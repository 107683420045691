<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 海报
 * @Date: 2020-11-12 20:32:26
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-20 19:02:50
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/comeOn/comeOnGuide.vue
-->
<template>
  <div>
    <div>
      <OpenAppHead :options="options" @getshowimg="getshowimg" />
    </div>
    <img
      class="bgcImg"
      src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-08/16/yuelvhuiQgylBZtQRg1607416313.png"
      alt=""
    >
    <div class="btmBtn" @click="openComeOn">
      <img
        class="openImg"
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-09/16/yuelvhuiNylw8Qc94r1604911941.png"
        alt=""
      >
    </div>
    <div v-if="showimg" class="mask" @click="closeshow">
      <div class="picture-box">
        <img class="picture" src="@/assets/images/wxtwo.jpg">
        <div class="btn">长按图片保存到手机相册</div>
        <!-- <div class="btn" style="background: #ff7800">预览海报长按分享</div> -->
      </div>
    </div>

    <!--        <div class="maskOpenApp">-->
    <!--            <div class="showCopyLinkContent">-->
    <!--                <img :src="InterceptionImg" alt="" class="showCopyLinkImg" @click="copyLink">-->
    <!--                <br>-->
    <!--                <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/21/yuelvhuiSeEpBaYYQm1608644479.png" alt="" class="showCopyLinkClose" @click="closeMask">-->
    <!--            </div>-->
    <!--        </div>-->
  </div>
</template>

<script>
import OpenAppHead from '@/component/GoodsDetail/openApp'

import Vue from 'vue'
import { isWeChat } from '@/utils/common/validate'
import { wxConfigInit } from '@/utils/wx-api'

Vue.config.ignoredElements = ['wx-open-launch-app']
export default {
  name: 'ComeOnGuide',
  components: {
    OpenAppHead
  },
  data() {
    return {
      showimg: false,
      topclose: true,
      isWxStyle: false,
      openAppdata: {
        text: '加油',
        wxExtinfo: {
          product_type: 61
        },
        handleError: (e) => {
        },
        handleLaunch: (e) => {
        }
      },
      InterceptionImg: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/22/yuelvhuijE6PwGSqSU1608647270.png'
    }
  },
  computed: {
    options() {
      const obj = {
        showtig: true
      }
      const info = {
        product_type: 61
      }
      obj.info = info
      return obj
    }
  },
  created() {
    this.configShare()
  },
  mounted() {
    this.isWxStyle = isWeChat()
  },
  methods: {
    isclose(t) {
      this.topclose = t
    },
    closeshow() {
      this.showimg = false
    },
    getshowimg(item) {
      this.showimg = item
    },
    openComeOn() {
      if (this.$route.path !== '/comeOnDataList') {
        this.$router.push({
          path: '/comeOnDataList'
        })
      }
    },
    // 复制跳转链接
    copyLink() {
      this.$router.push({
        path: '/JumpLink'
      })
    },
    // 关闭弹窗
    closeMask() {
      this.isWxStyle = false
    },
    configShare() {
      const shareInfo = {
        title: '全国10000+加油站接入，下单立减，边省边赚，让你到爱车"吃饱饭"', // 分享标题
        desc: '我在悦淘，绝对适合你，速来围观～', // 分享描述
        link: 'https://web.yuetao.vip/refueling-list?codeNumber=' + window.localStorage.getItem('recode'), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-16/19/yuelvhuiOHhTarxh8s1605525102.png' // 分享图标
      }
      wxConfigInit(shareInfo)
      // this.$store.dispatch('wxConfigInit', shareInfo)
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app {
  position: fixed;
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;

  .picture-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .picture {
      display: block;
      width: 280px;
      // height: 360px;
      background: rebeccapurple;
      margin: 0 auto;
    }

    .btn {
      width: 290px;
      height: 44px;
      border-radius: 22px;
      background: #ed2e2e;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }
}

.maskOpenApp {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .5);

  .wxStyle {
    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 50%;*/
    background: red;
  }

  .showCopyLinkContent {
    position: absolute;
    height: auto;
    left: 50%;
    margin-left: -130px;
    top: 50%;
    margin-top: -200px;

    .showCopyLinkImg {
      width: 260px;
      height: 326px;
    }

    .showCopyLinkClose {
      width: 31px;
      height: 31px;
      margin-top: 29px;
    }
  }
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ophead {
  height: 40px;
}

.ophead.vs {
  transition: height 0.6s ease-in-out;
  height: 0;
  display: none;
}

.wxStyle {
  height: 100px;
}

.bgcImg {
  position: relative;
  width: 100%;
  height: 100%;
}

.btmBtn {
  position: fixed;
  width: 100%;
  height: 77px;
  bottom: 0;
  padding: 17px 9px;
  background-color: #153074;
}

.openImg {
  width: 100%;
  margin: 0 auto;
}
</style>
